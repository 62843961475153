import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from "../index";
// 获取payment preparation列表数据
export const getPayPreLst = (
  params = {
    programBus: [],
    programCode: "",
    programName: "",
    current: 0,
    size: 0,
  }
): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/search", params);
};
// 获取RejectToSICCO列表数据
export const getRejectToSICCO = (params: any): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/rejectToSICCO", params);
};
export const postRejectToSICCO = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/paymentPreparation/rejectToSICCO/confirm",
    params
  );
};
// 获取SubmitToControlling列表数据
export const getSubmitToCon = (params: any): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/submitToControlling", params);
};
// 获取SubmitToControlling列表数据
export const postSubmitToCon = (params: any): Promise<any> => {
  return fetchPostPic(
    "/claimapi/paymentPreparation/submitToControlling/submit",
    params
  );
};
// 获取SubmitToControlling列表数据
export const getPaypreDetailLst = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/detail/search", params);
};
// 获取ModelYear列表数据
export const getModelYear = (): Promise<any> => {
  return fetchGet("/vapi/noAuth/get_property_lov?property=modelYear");
};
// 获取SubmitToControlling列表数据
export const exportPaypreDetailLst = (params = {}): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/detail/re", params);
};
// 获取RejectToSICCO列表数据
export const getDetilRejectToSICCO = (params: any): Promise<any> => {
  return fetchPost("/claimapi/paymentPreparation/detail/rejectToSICCO", params);
};
export const postDetilRejectToSICCO = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/paymentPreparation/detail/rejectToSICCO/confirm",
    params
  );
};
// 获取SubmitToControlling列表数据
export const getDetilSubmitToCon = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/paymentPreparation/detail/submitToControlling",
    params
  );
};
// 获取SubmitToControlling列表数据
export const postDetilSubmitToCon = (params: any): Promise<any> => {
  return fetchPost(
    "/claimapi/paymentPreparation/detail/submitToControlling/confirm",
    params
  );
};
//* 获取用户信息的接口
export const getUser = (): Promise<any> => {
  return fetchGet("/usapi/users/currentuser");
};
